const $ = require('jquery');

/* eslint-disable */

// JavaScript Document
jQuery.noConflict();
jQuery(($) => {

//handle mobile menu
$(document).ready(function() {
	//add the navicon html to our nav element
	$('<button class="menuButton" type="button">\
				<span class="menuButtonBars">\
					<span class="bar one"></span>\
					<span class="bar two"></span>\
					<span class="bar three"></span>\
				</span>\
				<span class="menuButtonText">Menu</span>\
     </button>').appendTo('.headerInner');

	//handle clicking button to open/close the menu
	$('.menuButton').on('click touchstart', (function(e){
			e.preventDefault();
			$('nav').toggleClass('open');
      $(this).toggleClass('open');
	}));

	//handle clicking parent items to open/close the submenu

	$('a.separator').on('click', (function(e){
			e.preventDefault();
			var thisa = $(this);
			var thisatext = thisa.text();
		  var thisli = thisa.parent();
			var thisul = thisa.siblings('.nav-child');
			var otherli = $('li.parent').not(thisli);
			var otherli = thisli.siblings('li');
      var othera = otherli.find('a');
			var otherul = otherli.find('.nav-child');
			var deeperul = thisul.find('.nav-child');
			var deeperli = thisli.find('.nav-child>.deeper');
			var deepera = deeperli.find('a');
					//do stuff
          //thisa.removeClass('open')
					otherli.removeClass('open');
					otherul.removeClass('open');
					deeperul.removeClass('open');
					deeperli.removeClass('open');
          deepera.removeClass('open');
          othera.removeClass('open');
					thisa.toggleClass('open');
					thisli.toggleClass('open');
					thisul.toggleClass('open');


		}));

}); //end document.ready

// handle map
$(document).ready(function() {
$('.iframe-container')
  .click(function(){
      $(this).find('iframe').addClass('clicked')})
  .mouseleave(function(){
      $(this).find('iframe').removeClass('clicked')});
});

$(document).ready(function() {
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
//taken from http://davidwalsh.name/javascript-debounce-function

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

var body_sizer = debounce(function() {
  var siteHeader = $('header').outerHeight(true);
  var windowHeight = $(window).height();
  var sliderHeight = windowHeight - siteHeader;
  $('.aboveContent .eSlider').height(sliderHeight);
  if (window.innerWidth < 1000) {
    $('.split-homepage__inner').height((sliderHeight / 2));
  } else {
    $('.split-homepage__inner').height(sliderHeight);
  }
},150);
body_sizer();
$(window).resize(body_sizer);

var bottleTrigger = debounce(function() {
  if($('.bottleHolder').length && $('.bottleHolder').hasClass('unanimated') && window.innerWidth >= 600){
    var aboveHeight = $('header').outerHeight(true) + $('.aboveContent .eSlider').outerHeight(true);
    var bottleHeight = $('.bottleHolder').outerHeight(true) * 0.35;
    var windowHeight = $(window).height()
    var triggerHeight = aboveHeight + bottleHeight - windowHeight;
    if($(document).scrollTop() >= triggerHeight){
      $('.bottleHolder').removeClass('unanimated');
      $('.bottleHolder').addClass('animateMe');
      $('.bottleHolder+.intro').addClass('animateMe');
    }
  }else if($('.bottleHolder').length && $('.bottleHolder').hasClass('unanimated') && window.innerWidth < 600){
    var aboveHeight = $('.numberHolder').offset().top;//get how far down the top of the item were looking to triffer is
    var sliderHeight = $('.numberHolder').outerHeight(true) * 0.15;//get height of target and multiply by percent we want on screen to trigger animation
    var windowHeight = $(window).height();//get window height
    var triggerHeight = aboveHeight + sliderHeight - windowHeight;//calc trigger height
    if($(document).scrollTop() >= triggerHeight){
      $('.numberHolder').removeClass('unanimated');//let classes do the work for us to keep things clean
      $('.numberHolder').addClass('animateMe');
    }

  }
},150);
bottleTrigger();
$(window).resize(bottleTrigger);
$(document).scroll(bottleTrigger);

//handles setting the lis to the same height on testimonials slider so they can be vertically centered
//from here http://stackoverflow.com/questions/6781031/use-jquery-css-to-find-the-tallest-of-all-elements
var testimonialSliderHeight = debounce(function() {
  var maxHeight = -1;
  //first reset hight to zero it can recalc height on resize as needed
  $('.testimonialsSlider > ul  > li').each(function() {
    $(this).height('');
  });

  //find the heighest max height
  $('.testimonialsSlider > ul  > li').each(function() {
   maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
  });

  //now set each li to the new max height
  $('.testimonialsSlider > ul  > li').each(function() {
   $(this).height(maxHeight);
  });

},250);
testimonialSliderHeight();
$(window).resize(testimonialSliderHeight);

//over 800px the gallery slider descriptions need to cover the slider, but the height could vary
var moveDescSlider = debounce(function() {
  if(window.innerWidth < 800){
    $('.gallerySliderDesc').css('margin-top', '');
  }else{
    var descHeight = $('.gallerySliderDesc').height();
    $('.gallerySliderDesc').css('margin-top', '-'+descHeight+'px');
  }

},250);
moveDescSlider();
$(window).resize(moveDescSlider);

//set wrapped var outside function so it doesn't get reset
var wrapped = 0;
var warrantyWrapper = debounce(function() {
if(($('.warranty-information .eAccordion').length) || ($('.characteristics .eAccordion').length)){
  if(window.innerWidth < 800 && !wrapped){
    accordionWrap();
    wrapped = 1;
    $('.eAccordion .accordionTitle:first-child').trigger('click');
  }else if(window.innerWidth > 800 && wrapped){
    accordionUnwrap();
    wrapped = 0;
  }else{
    //nothing needs to happen
  }
}
},100);
warrantyWrapper();
$(window).resize(warrantyWrapper);

//set var outside
var mobileTable = 0;
var tableTransformer = debounce(function() {
  var width = $('.tableContainer').innerWidth();
  if(window.innerWidth < 600 && !mobileTable){
    $('table td').css('width', width+'px');
    $('<span class="tableNav prev"><span class="icon-chevron-thin-left""></span></span><span class="tableNav next"><span class="icon-chevron-thin-right""></span></span>').appendTo('.tableWrapper');
    $('.tableNav.prev').hide();
    mobileTable = 1;
  }else if(window.innerWidth > 600 && mobileTable){
    $('table td').css('width', '');
    $('.tableWindow').css('margin-left', '');
    $('.tableNav').remove();
    mobileTable = 0;
  }else{
    //nothing needs to happen
  }

},100);
tableTransformer();
$(window).resize(tableTransformer);

}); //end document.ready

//click functions for the nav items on tables
$(document).ready(function () {
  $('body').on('click', '.tableNav.next', function (e) {
    e.preventDefault();
    //need to get current margin on each click
    var elWidth = $('.tableWindow').css('margin-left').replace("px", "");
    var containerWidth = $('.tableContainer').innerWidth();
    var elWidthPercent = 100 * elWidth / containerWidth;
    if(!$('.tableNav.prev').is(":visible")){
      $('.tableNav.prev').fadeIn();
    }
    switch (elWidthPercent){
      case 0:
        $('.tableWindow').css('margin-left', '-100%');
        break;
      case -100:
        $('.tableWindow').css('margin-left', '-200%');
        $('.tableNav.next').fadeOut('fast');
        break;
    }
  });
  $('body').on('click', '.tableNav.prev', function (e) {
    e.preventDefault();
    //need to get current margin on each click
    var elWidth = $('.tableWindow').css('margin-left').replace("px", "");
    var containerWidth = $('.tableContainer').innerWidth();
    var elWidthPercent = 100 * elWidth / containerWidth;
    if(!$('.tableNav.next').is(":visible")){
      $('.tableNav.next').fadeIn();
    }
    switch (elWidthPercent){
      case 0:
        $('.tableWindow').css('margin-left', '0%');
        break;
      case -100:
        $('.tableWindow').css('margin-left', '0%');
        $('.tableNav.prev').fadeOut('fast');
        break;
      case -200:
        $('.tableWindow').css('margin-left', '-100%');
        break;
    }
  });

}); //end document.ready

//append icon to slider on  homepage
$(document).ready(function() {
  $('<span class="icon-chevron-thin-down cta"></span>').appendTo('.aboveContent .eSlider');
}); //end document.ready



//animate scroll on click
$(document).ready(function () {
  $('.icon-chevron-thin-down.cta').on('click', function (e) {
    e.preventDefault();
    var scrollLength = $('.aboveContent .eSlider').outerHeight(true) + $('header').outerHeight(true);
    $('html, body').stop().animate({
      'scrollTop': scrollLength
    }, 1200, 'swing', function () {
    });
  });
});


// animated scroll for the 2 new 2018 shower campaign landing page buttons
// #shower-designer button
$(document).ready(function () {
  $('#scroll-to-shower-designer').on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: $('#shower-designer').offset().top - 100
    }, 1200);
  });
});
// #start-project button
$(document).ready(function () {
  $('.scroll-to-start-project').on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: $('#start-project').offset().top - 100
    }, 1200);
  });
});



/*no effect on mobile now
//handle adding class to swatches on touchstart to mimic hover
$(document).ready(function() {
  $('.swatches .itemWrapper').on('touchstart', function (e) {
    //e.preventDefault(); - dont prevent default, stops users from scrolling
    $('.swatches .itemWrapper').not(this).removeClass('hover');
    $(this).addClass('hover');
  });//end toucheven
}); //end document.ready
*/

//add js to handle closing system message so we don't have to include all of bootstrap for one functions
$(document).ready(function() {
  $('#system-message-container .close').on('click', function (e) {
    e.preventDefault();
    $('#system-message-container').slideToggle();
  });//end toucheven
}); //end document.ready


//controls shadowboxing videos throughout site
$(document).ready(function() {
  $('.shadowBox').on('click', function (e) {
    e.preventDefault();
    var iframesrc = $(this).attr('data-iframe');
    //alert(iframesrc);
    $('<div class="shadowBoxContainer">\
        <span class="shadowBoxClose icon-cross"><span class="visuallyHidden">Close video</span></span>\
        <div class="iframeHolder paddingLeftRight thinContainer">'+
        iframesrc +
        '</div>\
      </div>').hide().appendTo('body').fadeIn(300);
      wrapIframe();
    $('body').css('overflow', 'hidden');
  });//end click
  //call function to wrap iframe

  //click on anthing but iframe to close shadowbox, have to call on body click and check for .shadowBoxContainer since it's loaded in only on click, not dom ready
  $('body').on('click', '.shadowBoxContainer:not(iframe)', function (e) {
    e.preventDefault();
    $('.shadowBoxContainer').fadeOut(300, function(){ $(this).remove();});
    $('body').css('overflow', '');
  });//end click
  //also close shadow box on esc key press
  $(document).keyup(function(e) {
    if (e.keyCode == 27) { // escape key maps to keycode `27`
      $('.shadowBoxContainer').fadeOut(300, function(){ $(this).remove();});
      $('body').css('overflow', '');
    }
  });
});//end doc ready

//back to top button
$(document).ready(function(){
  // browser window scroll (in pixels) after which the "back to top" link is shown
  var offset = 300,
    //duration of the top scrolling animation (in ms)
    scroll_top_duration = 700,
    //get the bak to top link
    $back_to_top = $('.back-up-button');

  //hide or show the "back to top" link
  $(window).scroll(function(){
    ( $(this).scrollTop() > offset ) ? $back_to_top.addClass('is-visible') : $back_to_top.removeClass('is-visible');
  });

  //smooth scroll to top
  $back_to_top.on('click', function(event){
    event.preventDefault();
    $('body,html').animate({
      scrollTop: 0 ,
      }, scroll_top_duration
    );
  });

});//end doc ready for back to top

/*****standard js stuff****/
//handle making iframe map fluid
function wrapIframe(){
	$( 'iframe' ).each(function(index) { //we never have more than one page, but we might
		//set up some vars
		var thiswidth = $(this).attr('width'),
				thisheight = $(this).attr('height'),
				thisasprat = (thisheight / thiswidth) * 100;
		//soundcloud iframes have width="100%" attribute and a fixed height, so we don't need to do any of this
		if(thiswidth != '100%'){
			//wrap the iframe in something
			$(this).wrap('<div class="iframeWrapper-'+index+'">');
			//apply some css to the iframe
			$(this).css({
				'border': 'none',
				'position': 'absolute',
				'top': '0',
				'left': '0',
				'width': '100%',
				'height': '100%'
			});
			//apply some css to the wrapping element
			$('.iframeWrapper-'+index+'').css({
				'position': 'relative',
				'padding-top': thisasprat+'%',
				'margin': '0'
			});
			//all done :) -that was easy
		}
	});
}; //end document.ready

//accordion script
//now a function so we can call as needed
function accordionWrap(){
	$('.eAccordion').each(function(index) {
		//target all the header tags as the title of each accordion section
		$(this).children(':header').wrap('<div class="accordionTitle">');
		//need this for striping
		//$('.accordionTitle').filter(':even').addClass('odd');
		//do stuff for each title
		$('.accordionTitle').each(function(index2, element) {
			//use the text as id for the content, and the aria-controls attribute
			element = $(this).text();
			//but let's convert it to lowercase, no-spaces
			element = element.toLowerCase().replace(/[^a-zA-Z^0-9.]/g,'-').replace('--', '-');
			//add some accessability attributes to the titles
			$(this).attr('aria-controls', element);
			//add an id to the :header for analytics purposes
			$(this).children(':header').attr('id', 'accTitle-'+index+'-'+index2);
			//wrap all the content until the next header tag
			$(this).nextUntil('.accordionTitle').wrapAll('<div class="accordionContent" id="'+element+'" aria-expanded="false">');
			//hide the content
			$('.accordionContent').hide();
			//handle clicking on each title
			$(this).on('click tap', (function(e){
				//e.preventDefault();
				var target  = $(e.target);
				if( target.is('a') ) {
						return true; // True, because we don't want to cancel the 'a' click.
				}
				//finds other open rows and closes them (if desired)
				$('.accordionTitle.expanded').not(this).each(function() {
					$(this).removeClass('expanded');
					$(this).next('.accordionContent').attr('aria-expanded', 'false').slideToggle(333).toggleClass('open');
				});
				//open/close the row
				var thiscontent = $(this).next('.accordionContent');
				//toggle the class on the controlling element (mostly for styling)
				$(this).toggleClass('expanded');
				//slideToggle the controller's content
				//TODO: how about setting up this number as a variable in a data-timer attribute? Global on the .accordion div? How about other params?
				thiscontent.slideToggle(333).toggleClass('open');
				//adjust the aria-expanded attribute
				if( thiscontent.hasClass('open') ){
					thiscontent.attr('aria-expanded', 'true')
				} else {
					thiscontent.attr('aria-expanded', 'false')
				}
			}));//end click function
		});
	}); //end each .accordion function
}

//unwrap accordions so we have fleability as needed
function accordionUnwrap(){
  $('.eAccordion').each(function(index) {
    //unwrap the headers and the content
    $(this).find('.accordionTitle').children().unwrap().attr('id', '');
    $(this).find('.accordionContent').children().unwrap();
  }); //end each .accordion function
}


$(document).ready(function() {
  //only always wrap if not on warrenty page
  if( ($('body').hasClass('warranty-information')) || ($('body').hasClass('characteristics')) ){

  }else{
  	accordionWrap();
  }
}); //end doc ready


//Eric's placeholder functionality fix (allows different styling of placeholder text vs. real text) (makes behavior consistent across browsers)
//V.1.1 7-14-13 added no-placeholder class (now toggles between .placeholder/.no-placeholder) (useful for styling)
//V.1.2 9-4-13 added blur() function to no-placeholder fix to reset the placeholder after ajax submission
//v.1.3 6-26-14 changed triggers from .lt-ie10 to .no-placeholder - now dependant on modernizr placeholder check
//Eric's placeholder functionality fix for lt-ie10 (must replicate placeholder functionality by setting/removing field values)
$(document).ready(function() {
	if( $('html').hasClass('no-placeholder')){
		//on focus we remove .placeholder class and set value to blank
		$('[placeholder]').focus(function() {
	  		var input = $(this);
			if (input.val() == input.attr('placeholder')) {
				input.val('');
				input.removeClass('placeholder').addClass('no-placeholder');
	  		}
		}).blur(function() { //on blur we add .placeholder class and set value to placeholder
	  		var input = $(this);
				//special cgi check - this can't function in F2cSearchFreetextField fields, because it filters articles looking for 'Search...'
				if(input.hasClass('F2cSearchFreetextField')){
					//do nothing
				} else {
					if (input.val() == '' || input.val() == input.attr('placeholder')) {
						input.removeClass('no-placeholder').addClass('placeholder');
						input.val(input.attr('placeholder'));
					}
				}
		}).blur(); //run the blur function once on page load to set the placeholders
		//prevent the form from being submitted with placeholder values
		$('[placeholder]').parents('form').submit(function() {
	  		$(this).find('[placeholder]').each(function() {
				var input = $(this);
				if (input.val() == input.attr('placeholder')) {
		  			input.val('');
				}
	 		 }).blur(); //this fixes above "not ideal" issue... Hooray! Finally a good solution!
		});
	};	//end placeholder fix for non-modern browsers (lt-ie10)
}); //document.ready function


/*js from old site used for hikashop*/
$(document).ready(function() {

  // add Qty before all quantity inputs
  $('.hikashop_products_listing .hikashop_product_quantity_field').each(function() {
    $(this).before($('<span class="qty">').text("Qty"));
  });

  // highlight text when we enter a qty input box
  $('.hikashop_product_quantity_field').on('click', function () {
    $(this).select();
  });

  // add Shipping Rates image to end of product list
 // $('.hikashop_products ul').append($('<li class="shipRates"><img src="/images/shop/shipping-rates.jpg" alt="Shipping Rates: $5 for 1-4 samples or $10 for 5-8 samples." /></li>'));

  // add break after third item in footer nav
  var pageWidth = $('.com_hikashop').width();
  if (pageWidth < 600)
    $('.com_hikashop .footer ul li:nth-child(3)').after('<br>');

}); //end document.ready

$(document).ready(function() {
  var cbox = $('.same input[type="checkbox"]');

  // run functions when cbox is clicked
  cbox.change(function(){
    if (cbox.is(':checked')) {
      fillAddress();
    }
    if (!cbox.is(':checked')) {
      clearAddress();
    }
  });

  function fillAddress() {
    $('#hikashop_checkout_shipping_address_zone #address_firstname')
      .val($('#hikashop_checkout_billing_address_zone #address_firstname').val());
    $('#hikashop_checkout_shipping_address_zone #address_lastname')
      .val($('#hikashop_checkout_billing_address_zone #address_lastname').val());
    $('#hikashop_checkout_shipping_address_zone #address_company')
      .val($('#hikashop_checkout_billing_address_zone #address_company').val());
    $('#hikashop_checkout_shipping_address_zone #address_street')
      .val($('#hikashop_checkout_billing_address_zone #address_street').val());
    $('#hikashop_checkout_shipping_address_zone #address_city')
      .val($('#hikashop_checkout_billing_address_zone #address_city').val());

    $('#hikashop_checkout_shipping_address_zone #address_post_code')
      .val($('#hikashop_checkout_billing_address_zone #address_post_code').val());
    $('#hikashop_checkout_shipping_address_zone #address_telephone')
      .val($('#hikashop_checkout_billing_address_zone #address_telephone').val());
    // state dropdown works differently
    var bstate = $('#hikashop_checkout_billing_address_zone #data_billing_address_address_state option:selected').val();
    $('#hikashop_checkout_shipping_address_zone #data_shipping_address_address_state option[value=' + bstate + ']').attr('selected','selected');
  }

  function clearAddress() {
    $('#hikashop_checkout_shipping_address_zone input').val('');
  }

}); //end document.ready






// make the countTo numbers count up once they're on screen
// added by Jobin 17 August 2018
$(document).ready(function(){
  // only run this stuff if the Trigger ID is on an element
  if ($('#countToTrigger').length) {

    // trigger element height scroll point
    var countToTrigger = $('#countToTrigger').offset().top - 200;
    let countToHasRun = false;

    // setup the function
    function runCountTo() {
      // check if the scroll down distance is equal to or beyond the trigger distance
      if ($(document).scrollTop() >= countToTrigger && !countToHasRun) {
        $('.numberBlock .number').each(function() {
          $(this).countTo({
            speed: 1500,
            formatter: function (value, options) {
              value = value.toFixed(options.decimals);
              value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              return value;
            }
          });
        });
        countToHasRun = !countToHasRun;
      }
    }

    // run the function as scrolling occurs
    $(window).on('scroll', function() {
      runCountTo();
    });

  }
});





});
