const $ = require('jquery');

$(document).ready(() => {
  if ($('.shower-config').length) {
    initShowerConfig();
  }
});

function initShowerConfig() {
  preloadImages();
  fillOutColors();
  attachHandlers();
  clickDefaults();
}

function attachHandlers() {
  $('[data-wall-color]').on('click', (e) => {
    e.preventDefault();
    const $this = $(e.currentTarget);
    // get the wall color (could be an valid css color)
    const color = $this.data('wall-color');
    // find the group
    const group = $this.data('group');
    $('.shower-config__view-area__layer-wrapper__layer.layer-1').css('background-color', color);
    controlActive(group, $this);
  });

  $('[data-layer-image]').on('click', (e) => {
    const $this = $(e.currentTarget);
    // get the image url
    const img = $this.data('layer-image');
    // find the layer to update
    const layer = $this.data('layer');
    // find the group
    const group = $this.data('group');
    $(`.shower-config__view-area__layer-wrapper__layer.layer-${layer}`).css('background-image', `url(${img})`);
    controlActive(group, $this);
  });
}
function fillOutColors() {
  $.each($('[data-wall-color]'), (key, value) => {
    const $val = $(value);
    const color = $val.data('wall-color');
    $val.css('background-color', color);
  });
}


function controlActive(group, $activeItem) {
  $(`[data-group="${group}"].active`).removeClass('active');
  $activeItem.addClass('active');
}

function clickDefaults() {
  $.each($('.shower-config__config-options__group .default'), (key, value) => {
    $(value).click();
  });
}

function preloadImages() {
  $.each($('[data-layer-image]'), (key, value) => {
    const $this = $(value);
    // get the image url
    const img = $this.data('layer-image');
    const myImage = new Image();
    myImage.src = img;
  });
}
