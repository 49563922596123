const $ = require('jquery');

// Make it easier to use jQuery from the console
window.$ = $;
window.jQuery = $;

require('./main');
require('./shower-config');
require('./resposnive-table');
require('./countTo');
